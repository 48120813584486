const axios = require('axios');

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/bulk-messages`;
const routes = {
  get: {
    bulkMessages: originPath,
  },

  post: {
    bulkMessage: originPath,
    cancel: `${originPath}/{bulkMessageId}/cancel`,
    retryBulkMessage: `${originPath}/{bulkMessageId}/retry`,
  },

  put: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },

  delete: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = (query = { limit: 30, skip: 0 }) => {
  const params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const url = routes.get.bulkMessages + params;
  return axios.get(url, headers);
};

exports.create = ({
  files,
  filters: { userIdsCsv, ...filtersToStringify },
  ...primitiveValues
}) => {
  const url = routes.post.bulkMessage;
  const formData = new FormData();
  Object.entries(primitiveValues).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.set('filters', JSON.stringify(filtersToStringify));

  if (userIdsCsv) {
    formData.append('userIdsCsv', userIdsCsv);
  }

  if (files?.length > 0) {
    files.forEach(file => formData.append('files[]', file));
  }

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.cancel = bulkMessageId => {
  const url = routes.post.cancel.replace('{bulkMessageId}', bulkMessageId);
  return axios.post(url, {}, headers);
};

exports.retryBulkMessage = bulkMessageId => {
  const url = routes.post.retryBulkMessage.replace(
    '{bulkMessageId}',
    bulkMessageId
  );

  return axios.post(url, {}, headers);
};

exports.edit = ({ id, data }) => {
  const url = routes.put.bulkMessage.replace('{bulkMessageId}', id);

  const {
    files,
    filters: { userIdsCsv, ...filtersToStringify },
    ...primitiveValues
  } = data;

  const formData = new FormData();
  Object.entries(primitiveValues).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.set('filters', JSON.stringify(filtersToStringify));

  if (userIdsCsv) {
    formData.append('userIdsCsv', userIdsCsv);
  }

  const savedFiles = files.filter(file => !(file instanceof File));
  formData.set('savedFiles', JSON.stringify(savedFiles));

  files
    .filter(file => file instanceof File)
    .forEach(newFile => formData.append('files[]', newFile));

  return axios.put(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.delete = bulkMessageId => {
  const url = routes.delete.bulkMessage.replace(
    '{bulkMessageId}',
    bulkMessageId
  );
  return axios.delete(url, headers);
};
