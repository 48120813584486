<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <div>
    <v-data-table
      v-model:expanded="expanded"
      @update:options="getBulkMessages"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [30],
        'show-current-page': true,
        'show-first-last-page': true,
      }"
      :headers="headers"
      :items="bulkMessages"
      :loading="loading"
      :server-items-length="totalBulkMessages"
      disable-sort
      show-expand
      loading-text="Загружаю рассылки"
      item-key="_id"
      class="elevation-1"
      height="calc(100vh - 101px)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Список рассылок</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="() => openDialog()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ item: item }">
        <td :colspan="9" class="pa-0">
          <v-data-table
            v-if="item.items"
            :headers="bulkMessageItemsHeader"
            :items="item.items"
            class="elevation-0"
          >
            <template v-slot:item.chatId="{ item }">
              <v-avatar color="teal" size="32" class="mr-3">
                <img
                  v-if="chatsById.get(item.chatId).avatar"
                  :src="blobContainerUrl + chatsById.get(item.chatId).avatar"
                />
                <span v-else class="white--text">{{
                  chatsById
                    .get(item.chatId)
                    .from.first_name.charAt(0)
                    .toUpperCase()
                }}</span>
              </v-avatar>
              <span>{{ getName(item.chatId) }}</span>
            </template>

            <template v-slot:item.scheduleTime="{ item }">
              <span>{{ formatDate(item.scheduleTime) }}</span>
            </template>

            <template v-slot:item.status="{ item }">
              <v-icon :color="getStatusColor(item.status)">mdi-av-timer</v-icon>
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:item.title="{ item }">
        <span>{{
          item.title.length > 15 ? `${item.title.slice(0, 15)}...` : item.title
        }}</span>
      </template>

      <template v-slot:item.message="{ item }">
        <span>{{
          item.message.length > 15
            ? `${item.message.slice(0, 15)}...`
            : item.message
        }}</span>
      </template>

      <template v-slot:item.scheduleTimeStart="{ item }">
        <span>{{ formatDate(item.scheduleTime) }}</span>
      </template>

      <template v-slot:item.scheduleTimeEnd="{ item }">
        <span>
          {{
            item.items && item.items.length
              ? formatDate(item.items[item.items.length - 1].scheduleTime)
              : 'Немає часу'
          }}
        </span>
      </template>

      <template v-slot:item.sent="{ item }">
        <span
          class="text--darken-2 font-weight-medium"
          :class="{
            'green--text':
              item?.items?.length &&
              item?.items.filter(x => x.status === 'pending').length === 0,
          }"
        >
          {{ getBulkMessageItemsSummary(item) }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="isCancelable(item)"
          @click="cancelBulkMessage(item)"
          icon
          small
          class="mr-2"
          title="Отменить"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>

        <v-btn
          v-if="isEditable(item)"
          @click="() => openDialog(item)"
          icon
          small
          class="mr-2"
          title="Редактировать"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="isRetriable(item)"
          @click="() => retryBulkMessage(item)"
          icon
          small
          class="mr-2"
          title="Перезапустить"
        >
          <v-icon>mdi-replay</v-icon>
        </v-btn>

        <v-btn
          v-if="isRemovable(item)"
          @click="deleteBulkMessage(item._id)"
          icon
          small
          title="Удалить"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <bulk-messages-dialog
      :show="show"
      :bulkMessageToEdit="bulkMessageToEdit"
      @create="getBulkMessages"
      @edit="getBulkMessages"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import bulkMessagesApi from '@/api/admin/bulkMessages';
import BulkMessagesDialog from '@/components/admin/dialogs/BulkMessagesDialog/BulkMessagesDialog';
import { formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'BulkMessages',
  components: {
    BulkMessagesDialog,
  },

  data() {
    return {
      totalBulkMessages: 0,
      bulkMessages: [],
      options: {
        page: 1,
        itemsPerPage: 30,
        sortBy: [],
        sortDesc: [],
      },
      show: false,
      delete: false,
      cancel: false,
      retry: false,
      loading: false,
      bulkMessageToEdit: null,

      expanded: [],
      headers: [
        { text: 'Название', value: 'title' },
        { text: 'Сообщение', value: 'message' },
        { text: 'Начало', value: 'scheduleTimeStart', align: 'center' },
        { text: 'Завершение', value: 'scheduleTimeEnd', align: 'center' },
        {
          text: 'Приблизительное количество',
          value: 'approximateNumberOfItems',
          align: 'center',
        },
        {
          text: 'Отправлено/Ошибка/Ожидание/Всего',
          value: 'sent',
          align: 'center',
        },
        { text: 'Действия', value: 'actions', align: 'right' },
      ],

      bulkMessageItemsHeader: [
        { text: 'Кому', value: 'chatId' },
        { text: 'На дату', value: 'scheduleTime', align: 'center' },
        { text: '', value: '', align: '', sortable: false },
        { text: '', value: '', align: '', sortable: false },
        {
          text: 'Ошибка',
          value: 'errorMessage',
          align: 'center',
          sortable: false,
        },
        { text: 'Статус', value: 'status', align: 'center', sortable: false },
      ],

      blobContainerUrl: BLOB_CONTAINER_URL,
    };
  },

  computed: {
    chatsById() {
      return new Map(this.$store.state.adminChat.chats.map(i => [i.chatId, i]));
    },
  },

  methods: {
    openDialog(bulkMessage) {
      this.show = true;
      if (bulkMessage) {
        this.bulkMessageToEdit = bulkMessage;
      }
    },

    closeDialog() {
      this.show = false;
      this.bulkMessageToEdit = null;
    },

    isRemovable(bulkMessage) {
      const removableStatuses = [
        'pending',
        'scheduled',
        'canceled',
        'error',
        'retry_scheduled',
      ];
      return removableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    isEditable(bulkMessage) {
      const editableStatuses = ['pending', 'scheduled', 'retry_scheduled'];
      return editableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    isCancelable(bulkMessage) {
      const cancelableStatuses = ['pending', 'scheduled', 'retry_scheduled'];
      return cancelableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    isRetriable(bulkMessage) {
      const { items, status } = bulkMessage;
      const totalItems = items.length;

      if (totalItems === 0) {
        return false;
      }

      const unretriableStatuses = ['scheduled', 'sending', 'retry_scheduled'];
      if (unretriableStatuses.includes(status)) {
        return false;
      }

      const pendingItemsLength = items.filter(
        item => item.status === 'pending'
      ).length;

      return pendingItemsLength > 0;
    },

    getName(chatId) {
      let chat = this.chatsById.get(chatId);
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    getStatusColor(status) {
      let color = '';

      switch (status) {
        case 'pending':
          color = 'grey';
          break;

        case 'sent':
          color = 'green';
          break;

        case 'error':
          color = 'red';
          break;
      }

      return color;
    },

    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },

    getBulkMessageItemsSummary(bulkMessage) {
      const { items } = bulkMessage;
      const totalItems = items.length;

      if (totalItems === 0) {
        return '-/-/-/-';
      }

      const { sent, error, pending } = items.reduce(
        (acc, curr) => {
          acc[curr.status] += 1;
          return acc;
        },
        {
          sent: 0,
          error: 0,
          pending: 0,
        }
      );

      const percentageCompleted = parseInt((1 - pending / totalItems) * 100);

      return `${sent}/${error}/${pending}/${totalItems} (${percentageCompleted}%)`;
    },

    getBulkMessages(options = this.options) {
      this.loading = true;
      const { page, itemsPerPage } = options;

      bulkMessagesApi
        .getAll({ limit: itemsPerPage, skip: (page - 1) * itemsPerPage })
        .then(({ data }) => {
          const { total, bulkMessages } = data;
          this.bulkMessages = bulkMessages;
          this.totalBulkMessages = total;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async cancelBulkMessage(bulkMessage) {
      if (!this.cancel) {
        this.cancel = true;
        setTimeout(() => {
          this.cancel = false;
        }, 500);
        return;
      }

      this.loading = true;

      await bulkMessagesApi
        .cancel(bulkMessage._id)
        .then(res => {
          this.getBulkMessages();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async deleteBulkMessage(bulkMessageId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      this.loading = true;

      await bulkMessagesApi
        .delete(bulkMessageId)
        .then(res => {
          this.getBulkMessages();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async retryBulkMessage(bulkMessage) {
      if (!this.retry) {
        this.retry = true;
        setTimeout(() => {
          this.retry = false;
        }, 500);
        return;
      }

      this.loading = true;

      await bulkMessagesApi
        .retryBulkMessage(bulkMessage._id)
        .then(res => {
          this.getBulkMessages();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
