<template>
  <v-footer v-if="!!currentChatId" app inset height="auto">
    <div style="position: relative" class="flex-grow-1">
      <p style="color: red" v-if="this.isBotBlocked">
        Користувач заблокував бот, ви не можете надсилати йому повідомлення
      </p>
      <v-card v-if="hasForwardMessages" class="pa-2" elevation="0">
        <div class="d-flex align-center justify-space-between">
          <div class="mr-4">
            <v-icon color="blue">mdi-forward</v-icon>
          </div>
          <div class="flex-grow-1">
            <div>
              <div class="blue--text" style="font-weight: 500">
                {{ forwardMessageTitle }}
              </div>
              <div class="break-word">{{ forwardMessageText }}</div>
            </div>
          </div>
          <div>
            <v-btn v-if="!loading" @click="cancelForward" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card>

      <v-card
        v-if="hasReplyToMessage"
        class="pa-2"
        elevation="0"
        style="width: 100%; position: absolute; bottom: calc(100% + 0.5rem)"
      >
        <div class="d-flex align-center justify-space-between">
          <div class="mr-4">
            <v-icon color="blue">mdi-reply</v-icon>
          </div>
          <div class="flex-grow-1">
            <div>
              <div class="blue--text" style="font-weight: 500">
                {{
                  replyToMessage.supportId === null
                    ? `${replyToMessage.from.first_name} ${replyToMessage.from.last_name}`
                    : 'Менеджер'
                }}
              </div>
              <div class="break-word">{{ replyToBarText }}</div>
            </div>
          </div>
          <div>
            <v-btn @click="cancelReply" icon><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>
      </v-card>

      <form
        @submit.prevent="handleSendMessage"
        action=""
        class="d-flex justify-space-between align-center flex-grow-1"
      >
        <div class="mr-2 d-flex justify-space-between align-center flex-grow-0">
          <v-file-input
            @change="addFiles"
            v-model="inputs.files"
            :disabled="loading"
            class="mr-1"
            hide-input
            dense
            truncate-length="22"
            multiple
          ></v-file-input>
        </div>

        <div class="mr-4 flex-grow-1" style="position: relative">
          <v-textarea
            @keydown.enter.exact.prevent="handleSendMessage"
            @paste="handlePaste"
            v-model="messageInput"
            id="messageInput"
            class="textarea"
            :background-color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            label="Написати повідомлення..."
            auto-grow
            outlined
            dense
            hide-details
            rows="2"
            row-height="15"
            ref="messageInput"
          ></v-textarea>
        </div>

        <template
          v-if="(hasSomeValues && !isMessageEditMode) || hasForwardMessages"
        >
          <v-btn
            v-if="isStandardMode"
            type="submit"
            :loading="loading"
            color="blue"
            class="white--text"
            ><v-icon>mdi-send</v-icon></v-btn
          >
        </template>

        <template v-else>
          <message-templates-dialog
            v-if="isStandardMode"
            @showSendTemplateDialog="this.handleShowSendTemplateDialog"
          ></message-templates-dialog>
        </template>

        <template v-if="isMessageEditMode">
          <v-btn @click="clearEdit" color="secondary" class="white--text mr-2"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-btn
            type="submit"
            color="blue"
            :loading="loading"
            class="white--text"
            ><v-icon>mdi-send</v-icon></v-btn
          >
        </template>
      </form>
    </div>

    <send-template-dialog
      @close="handleCloseSendTemplateDialog"
      :template="template"
    />
  </v-footer>
</template>

<script>
import messagesApi from '@/api/messages';
import { getObjectByKeyValue, getScreenshot } from '@/lib/utils';
import { scrollToBottom } from '@/lib/ScrollController';
import MessageTemplatesDialog from '@/components/dialogs/MessageTemplatesDialog';
import SendTemplateDialog from '../../dialogs/SendTemplateDialog.vue';
import { convertBytesToMegabytes } from '../../../lib/utils';

export default {
  name: 'MessageBar',
  components: {
    MessageTemplatesDialog,
    SendTemplateDialog,
  },

  data() {
    return {
      loading: false,
      photos: [],
      files: [],

      inputs: {
        photos: [],
        files: [],
      },

      template: {
        show: false,
        title: '',
        text: '',
        files: [],
      },
    };
  },

  watch: {
    messageInput(newText, oldText) {
      this.$nextTick(() => {
        const textareaElement = this.$refs.messageInput.$el;
        const currentHeight = textareaElement.scrollHeight;

        this.$emit('updateMessageBarHeight', currentHeight);
      });
    },
  },
  computed: {
    chats() {
      return this.$store.state.chat.chats;
    },

    replyToMessage: {
      get() {
        return this.$store.state.chat.replyToMessage;
      },

      set(message) {
        this.$store.commit('chat/SET_REPLY_TO_MESSAGE', message, {
          root: true,
        });
      },
    },

    hasReplyToMessage() {
      return (
        Object.keys(this.replyToMessage).length > 0 &&
        this.replyToMessage.chatId === this.currentChat.chatId
      );
    },

    replyToBarText() {
      if (this.replyToMessage.text) {
        return this.replyToMessage.text;
      } else if (this.replyToMessage.document) {
        return 'Документ';
      } else if (this.replyToMessage.photo) {
        return 'Фото';
      } else if (this.replyToMessage.voice) {
        return 'Голосове повідомлення';
      } else if (this.replyToMessage.videoNote) {
        return 'Відео повідомлення';
      } else if (this.replyToMessage.video) {
        return 'Відео';
      }

      return '';
    },

    hasSomeValues() {
      return (
        this.messageInput.length > 0 || this.files.length || this.photos.length
      );
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    chatModes() {
      return this.$store.state.chat.chatModes;
    },

    chatMode: {
      get() {
        return this.$store.state.chat.chatMode;
      },

      set(mode) {
        this.$store.commit('chat/SET_CHAT_MODE', mode, { root: true });
      },
    },

    isStandardMode() {
      return this.$store.getters['chat/isStandardMode'];
    },

    isMessageEditMode() {
      return this.$store.getters['chat/isMessageEditMode'];
    },

    messageInput: {
      get() {
        return this.$store.state.chat.messageInput;
      },

      set(text) {
        this.$store.commit('chat/SET_MESSAGE_INPUT', text, { root: true });
      },
    },

    currentMessage: {
      get() {
        return this.$store.state.chat.currentMessage;
      },

      set(message) {
        this.$store.commit('chat/SET_CURRENT_MESSAGE', message, { root: true });
      },
    },

    forwardMessages() {
      return this.$store.state.chat.forwardMessages;
    },

    forwardTo() {
      return this.$store.state.chat.forwardTo;
    },

    isBotBlocked() {
      return this.$store.getters['chat/isBotBlocked'];
    },

    hasForwardMessages() {
      return (
        this.forwardMessages.length > 0 &&
        this.forwardTo === this.currentChat.chatId
      );
    },

    forwardMessageTitle() {
      if (this.forwardMessages.length > 1) {
        let senderNames = [];

        this.forwardMessages.forEach((message, index) => {
          if (
            message.forwardFrom &&
            senderNames.indexOf(message.forwardFrom.senderName) < 0
          ) {
            senderNames.push(message.forwardFrom.senderName);
          } else if (message.replyToMessage) {
            let replyName = message.replyToMessage.isBot
              ? `${message.replyToMessage.from.first_name} ${message.replyToMessage.from.last_name}`
              : 'Менеджер';

            if (senderNames.indexOf(replyName) < 0) senderNames.push(replyName);
          } else {
            const chat = getObjectByKeyValue(
              this.chats,
              message.chatId,
              'chatId'
            );
            let name = `${chat.from.first_name || ''} ${
              chat.from.last_name || ''
            }`;
            if (name.trim().length === 0) name = chat.from.username;

            name =
              message.supportId === null
                ? name.trim() || 'No name'
                : 'Менеджер';

            if (senderNames.indexOf(name) < 0) senderNames.push(name);
          }
        });

        return senderNames.join(', ');
      } else {
        if (this.forwardMessages[0].forwardFrom)
          return this.forwardMessages[0].forwardFrom.senderName;

        if (this.forwardMessages[0].replyToMessage) {
          return this.forwardMessages[0].replyToMessage.isBot
            ? `${this.forwardMessages[0].replyToMessage.from.first_name} ${this.forwardMessages[0].replyToMessage.from.last_name}`
            : 'Менеджер';
        }

        const chat = getObjectByKeyValue(
          this.chats,
          this.forwardMessages[0].chatId,
          'chatId'
        );
        let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
        if (name.trim().length === 0) name = chat.from.username;

        return this.forwardMessages[0].supportId === null
          ? name.trim() || 'No name'
          : 'Менеджер';
      }
    },

    forwardMessageText() {
      if (this.forwardMessages.length > 1) {
        return this.forwardMessages.length + ' Повідомлення';
      } else {
        if (this.forwardMessages[0].text) return this.forwardMessages[0].text;
      }

      return 'Не текст';
    },
  },

  methods: {
    handlePaste(e) {
      const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
      if (clipboardData.types.indexOf('Files') < 0) return;
      let files = [...clipboardData.files];

      files = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;
        const screenshot = getScreenshot(file);

        return {
          status: '',
          file: screenshot || file,
          ...(thumbnail ? { thumbnail } : {}),
          size: convertBytesToMegabytes(file.size),
        };
      });

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'uploadDialog',
          data: files,
        },
        { root: true }
      );
    },

    cancelForward() {
      this.$store.commit('chat/SET_FORWARD_FROM', 0, { root: true });
      this.$store.commit('chat/SET_FORWARD_TO', 0, { root: true });
      this.$store.commit('chat/SET_FORWARD_MESSAGES', [], { root: true });

      document.getElementById('messageInput').focus();
    },

    cancelReply() {
      this.$store.commit('chat/SET_REPLY_TO_MESSAGE', {}, { root: true });
      document.getElementById('messageInput').focus();
    },

    addFiles(files) {
      if (files.length === 0) return;

      files = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

        return {
          status: '',
          file,
          ...(thumbnail ? { thumbnail } : {}),
          size: convertBytesToMegabytes(file.size),
        };
      });

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'uploadDialog',
          data: files,
        },
        { root: true }
      );

      this.inputs.files = [];
    },

    handleSendMessage() {
      if (this.loading) return;
      const hasMessage =
        this.messageInput.trim().length > 0 && !!this.currentChatId;

      switch (true) {
        case this.isStandardMode:
          (async () => {
            try {
              this.loading = true;
              // if (hasPhotos) await this.sendPhotos();
              // if (hasDocuments) await this.sendDocuments();
              if (hasMessage) await this.sendMessage();

              scrollToBottom();
            } catch (e) {
              console.error(e);
              if (e.response.status === 403) {
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
              }
            } finally {
              this.loading = false;
            }
          })();
          break;
        case this.isMessageEditMode:
          (() => {
            try {
              this.loading = true;
              this.editMessage();
              this.$store.commit('chat/SET_IS_BOT_BLOCKED', false);
            } catch (e) {
              console.error(e);
              if (e.response.status === 403) {
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
              }
            } finally {
              this.loading = false;
            }
          })();
          break;
      }

      if (this.hasForwardMessages) {
        (async () => {
          try {
            this.loading = true;
            await this.sendForwarded();
          } catch (e) {
            console.error(e);
          } finally {
            this.loading = false;
          }
        })();
      }
      // update messageBarHeight after message has been sent
      setTimeout(() => {
        const textareaElement = this.$refs.messageInput.$el;
        const currentHeight = textareaElement.scrollHeight;
        this.$emit('updateMessageBarHeight', currentHeight);
      }, 0);
    },

    sendForwarded() {
      return new Promise(async (resolve, reject) => {
        try {
          let forwardMessages = [...this.forwardMessages];
          const forwardTo = this.$store.state.chat.forwardTo;
          const forwardFrom = this.$store.state.chat.forwardFrom;

          for (let i = 0; i < forwardMessages.length; i++) {
            await messagesApi
              .forward(forwardTo, forwardFrom, forwardMessages[i].messageId)
              .catch(e => {
                console.error(e);
              });
          }

          this.$store.commit('chat/SET_FORWARD_FROM', 0, { root: true });
          this.$store.commit('chat/SET_FORWARD_TO', 0, { root: true });
          this.$store.commit('chat/SET_FORWARD_MESSAGES', [], { root: true });

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    sendMessage() {
      return new Promise(async (resolve, reject) => {
        try {
          //4096 max message length by telegram
          const tempMessageParts =
            this.messageInput.match(/(.|[\r\n]){1,4096}/g);
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          let successIndexes = [];
          this.messageInput = '';
          this.replyToMessage = {};

          for (let i = 0; i < tempMessageParts.length; i++) {
            await messagesApi
              .create(
                this.currentChatId,
                tempMessageParts[i],
                tempReplyToMessage.messageId
              )
              .then(res => {
                successIndexes.push(i);
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', false);
              })
              .catch(e => {
                this.replyToMessage = tempReplyToMessage || {};
                console.error(e);
                if (e.response.status === 403) {
                  this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
                }
                successIndexes.forEach(successIndex => {
                  tempMessageParts.splice(successIndex, 1);
                });

                successIndexes = [];

                tempMessageParts.forEach(x => {
                  this.messageInput += x;
                });

                console.error(e);
                if (e.response.status === 403) {
                  this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
                }
              });
          }

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    editMessage() {
      if (this.messageInput.trim().length === 0 || !this.currentChatId) {
        this.messageInput = '';
        return;
      }

      const tempCurrentMessage = this.currentMessage;
      const tempMessageInput = this.messageInput.trim();

      this.clearEdit();

      messagesApi
        .edit(
          tempCurrentMessage.messageId,
          this.currentChatId,
          tempMessageInput
        )
        .catch(e => {
          this.chatMode = this.chatModes.editMessage;
          this.currentMessage = tempCurrentMessage;
          this.messageInput = tempMessageInput;
          console.error(e);
          if (e.response.status === 403) {
            this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
          }
        });
    },

    clearEdit() {
      this.chatMode = this.chatModes.standard;
      this.currentMessage = {};
      this.messageInput = '';

      this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'add-edit-message', {
        root: true,
      });

      setTimeout(() => {
        const textareaElement = this.$refs.messageInput.$el;
        const currentHeight = textareaElement.scrollHeight;
        this.$emit('updateMessageBarHeight', currentHeight);
      }, 0);
    },

    handleShowSendTemplateDialog(template) {
      this.template = {
        ...template,
        show: true,
      };
    },

    handleCloseSendTemplateDialog() {
      this.template.show = false;
      this.template.files = [];
      this.template.text = '';
      this.template.title = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;

  ::v-deep .v-text-field__slot {
    max-height: calc(100vh - 144px);
    overflow: auto;
  }
}

.break-word {
  word-break: break-word;
}
</style>
