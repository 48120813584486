import { MONTHS } from './const';

export const getObjectByKeyValue = (arr, value, key = 'id') => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) return arr[i];
  }

  return null;
};

export const getObjectIndexByKeyValue = (arr, value, key = 'id') =>
  arr.findIndex(item => item[key] === value) ?? null;

export const getExistenceByKeyValue = (arr, value, key = 'id') => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) return true;
  }

  return false;
};

export const formatDate = (date = new Date(), separator = '/') => {
  let fullDate = new Date(date),
    d = fullDate.getDate().toString(),
    m = (fullDate.getMonth() + 1).toString(),
    y = fullDate.getFullYear().toString();

  d = d.length === 1 ? `0${d}` : d;
  m = m.length === 1 ? `0${m}` : m;

  return `${d}${separator}${m}${separator}${y}`;
};

export const formatTime = (date = new Date()) => {
  let fullDate = new Date(date),
    h = fullDate.getHours().toString(),
    m = fullDate.getMinutes().toString();
  h = h.length === 1 ? `0${h}` : h;
  m = m.length === 1 ? `0${m}` : m;

  return `${h}:${m}`;
};

export const getScreenshot = file => {
  const currentTime = new Date().getTime();
  const isScreenshot = file.lastModified === currentTime;
  let screenshot = null;

  if (isScreenshot) {
    const screenshotName = `image_${currentTime}.png`;

    if (screenshotName) {
      screenshot = new File([file], screenshotName, {
        type: file.type,
        lastModified: file.lastModified,
      });
    }
  }

  return screenshot;
};

export const isSameMonth = (date1, date2) =>
  new Date(date1).getMonth() === new Date(date2).getMonth();

export const getMonthFromDate = (date = '') =>
  MONTHS[new Date(date).getMonth()];

export const getYearFromDate = (date = '') => new Date(date).getFullYear();

export const getISODate = date => new Date(date).toISOString().slice(0, 10);

export const convertBytesToMegabytes = sizeInBytes => {
  const sizeInMegabytes = sizeInBytes / Math.pow(1024, 2);
  return parseFloat(sizeInMegabytes.toFixed(2));
};
