<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="upload-dialog">
      <v-card-title>
        <span v-if="files.length > 1" class="text-h5">
          Количество файлов: {{ files.length }}
        </span>
      </v-card-title>

      <v-card-text>
        <div
          style="overflow-x: hidden; overflow-y: auto; max-height: 280px"
          :class="{ 'pr-1': files.length > 1 }"
        >
          <ul style="list-style: none; padding: 0" class="file-list">
            <li v-for="(fileItem, index) in files" class="file-list__item">
              <div v-if="fileItem.thumbnail" style="position: relative">
                <div class="thumbnail">
                  <div class="thumbnail__container">
                    <div
                      class="thumbnail__image"
                      :style="{
                        'background-image': `url(${fileItem.thumbnail})`,
                      }"
                    ></div>
                  </div>
                </div>

                <div
                  style="
                    position: absolute;
                    top: 0.25rem;
                    right: 0.25rem;
                    background: rgba(69, 90, 100, 0.6);
                  "
                  class="d-flex align-center rounded"
                >
                  <v-btn small dark icon class="mr-1">
                    <v-icon @click="openImageEditor(index)" small
                      >mdi-pencil</v-icon
                    >
                  </v-btn>

                  <v-file-input
                    prepend-icon="mdi-sync"
                    @change="changeFile($event, index)"
                    v-model="inputs.files"
                    class="reload-btn mr-1 mt-0"
                    hide-input
                    dense
                    dark
                  />

                  <v-btn small dark icon>
                    <v-icon @click="removeFile(index)" small>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div
                  style="position: absolute; bottom: 0.25rem; right: 0.25rem"
                >
                  <span class="bold blue-grey lighten-5">
                    {{ `${fileItem.size} МБ` }}
                  </span>
                </div>
              </div>

              <div v-else>
                <div class="d-flex align-center justify-space-between py-2">
                  <div :class="{ 'red--text': fileItem.status === 'error' }">
                    <v-icon
                      dense
                      :color="fileItem.status === 'error' ? 'red' : ''"
                      >mdi-file-outline</v-icon
                    >
                    <span
                      class="d-inline-block ml-1 text-truncate"
                      style="max-width: 240px; vertical-align: middle"
                      >{{ fileItem.file.name }}</span
                    >
                  </div>

                  <div class="rounded d-flex align-center">
                    <v-file-input
                      prepend-icon="mdi-sync"
                      @change="changeFile($event, index)"
                      v-model="inputs.files"
                      class="reload-btn mr-1 mt-0"
                      hide-input
                      dense
                    />

                    <v-btn small icon>
                      <v-icon @click="removeFile(index)" small
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="d-flex red--text" v-if="isPhotoTooLarge">
          Изображение размером больше 10 МБ можно отправить только в несжатом
          виде
        </div>

        <div>
          <v-checkbox
            v-if="hasPhotos"
            v-model="compressImage"
            label="Сжать изображение"
          ></v-checkbox>
        </div>

        <div>
          <v-textarea
            @keydown.enter.exact.prevent="handleSend"
            @paste="handlePaste"
            v-model="inputs.text"
            ref="textInput"
            class="textarea"
            :error="isTooLongCaption"
            :background-color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            :label="
              isComment ? 'Написать кометарий...' : 'Написать описание...'
            "
            auto-grow
            outlined
            dense
            hide-details
            rows="1"
            row-height="15"
          ></v-textarea>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="clickInput" color="blue mr-1" text dark elevation="0">
          <input
            @change="addFiles"
            ref="file-input"
            type="file"
            multiple
            style="width: 0"
          />
          Добавить
        </v-btn>
        <v-spacer />
        <v-btn @click="close" color="grey darken-1" text>Отменить</v-btn>
        <v-btn
          @click="handleSend"
          :disabled="isPhotoTooLarge"
          color="blue"
          text
        >
          Отправить
        </v-btn>
      </v-card-actions>
    </v-card>
    <image-editor
      :containerId="'upload-dialog-image-editor'"
      :isOpen="isImageEditorOpen"
      :image="editedImage"
      :onClose="closeImageEditor"
      :onSave="changeFile"
    />
  </v-dialog>
</template>

<script>
import messagesApi from '@/api/messages';
import { scrollToBottom } from '@/lib/ScrollController';
import { getScreenshot } from '@/lib/utils';
import ImageEditor from '@/components/imageEditor/ImageEditor';
import { convertBytesToMegabytes } from '../../lib/utils';

export default {
  name: 'UploadDialog',
  components: {
    ImageEditor,
  },

  data() {
    return {
      compressImage: true,
      tempFiles: [],

      inputs: {
        files: [],
        text: '',
      },

      isImageEditorOpen: false,
      editedImage: null,
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.$store.commit('ui/ADD_ACTION', 'open-upload-dialog', {
          root: true,
        });
        setTimeout(() => this.$refs['textInput'].focus(), 100);
      } else {
        setTimeout(() => {
          this.inputs.text = '';

          this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-upload-dialog', {
            root: true,
          });
          document.getElementById('messageInput').focus();
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return this.$store.state.ui.currentDialog.name === 'uploadDialog';
      },

      set(show) {
        if (!show)
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      },
    },

    files: {
      get() {
        return (
          (this.$store.state.ui.currentDialog.name === 'uploadDialog' &&
            this.$store.state.ui.currentDialog.data) ||
          []
        );
      },

      set(files) {
        this.$store.commit('ui/SET_CURRENT_DIALOG_DATA', files, { root: true });
      },
    },

    hasPhotos() {
      return this.files.filter(x => x.hasOwnProperty('thumbnail')).length > 0;
    },

    isCaption() {
      return this.files.length === 1 && this.inputs.text;
    },

    isComment() {
      return this.files.length > 1 && this.inputs.text;
    },

    isTooLongCaption() {
      return !!(
        this.isCaption &&
        this.inputs.text &&
        this.inputs.text.length > 1024
      );
    },

    isPhotoTooLarge() {
      return (
        this.compressImage &&
        this.files.some(file => file.thumbnail && file.size > 10)
      );
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    replyToMessage: {
      get() {
        return this.$store.state.chat.replyToMessage;
      },

      set(message) {
        this.$store.commit('chat/SET_REPLY_TO_MESSAGE', message, {
          root: true,
        });
      },
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    hasReplyToMessage() {
      return (
        Object.keys(this.replyToMessage).length > 0 &&
        this.replyToMessage.chatId === this.currentChat.chatId
      );
    },
  },

  methods: {
    clickInput() {
      this.$refs['file-input'].click();
    },

    handlePaste(e) {
      const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
      if (clipboardData.types.indexOf('Files') < 0) return;
      let files = [...clipboardData.files];

      files = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;
        const screenshot = getScreenshot(file);

        return {
          status: '',
          file: screenshot || file,
          ...(thumbnail ? { thumbnail } : {}),
          size: convertBytesToMegabytes(file.size),
        };
      });

      this.files = [...this.files, ...files];
    },

    handleSend() {
      if (this.isTooLongCaption) return;

      let photos = this.files.filter(x => x.hasOwnProperty('thumbnail'));
      let documents = this.files.filter(x => !x.hasOwnProperty('thumbnail'));

      const hasPhotos = photos.length > 0 && !!this.currentChatId;
      const hasDocuments = documents.length > 0 && !!this.currentChatId;
      const isComment = this.isComment;

      const caption = this.isCaption ? this.inputs.text : '';

      (async () => {
        this.$store.commit(
          'ui/SET_CURRENT_DIALOG',
          { name: 'loaderDialog' },
          { root: true }
        );

        if (isComment) await this.sendMessage();

        if (hasPhotos)
          this.compressImage
            ? await this.sendPhotos(photos, caption)
            : await this.sendDocuments(photos, caption);

        if (hasDocuments) await this.sendDocuments(documents, caption);

        if (this.tempFiles.length) {
          this.$store.commit('ui/ADD_ACTION', 'open-upload-dialog', {
            root: true,
          });
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            {
              name: 'uploadDialog',
              data: this.tempFiles,
            },
            { root: true }
          );

          this.tempFiles = [];
        } else {
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
        }

        this.$nextTick(() => {
          scrollToBottom();
        });
      })();
    },

    sendMessage() {
      return new Promise(async (resolve, reject) => {
        try {
          //4096 max message length by telegram
          const tempMessageParts =
            this.inputs.text.match(/(.|[\r\n]){1,4096}/g);
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          let successIndexes = [];
          this.inputs.text = '';
          this.replyToMessage = {};

          for (let i = 0; i < tempMessageParts.length; i++) {
            await messagesApi
              .create(
                this.currentChatId,
                tempMessageParts[i],
                tempReplyToMessage.messageId
              )
              .then(res => {
                successIndexes.push(i);
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', false);
              })
              .catch(e => {
                this.replyToMessage = tempReplyToMessage || {};

                successIndexes.forEach(successIndex => {
                  tempMessageParts.splice(successIndex, 1);
                });

                successIndexes = [];

                tempMessageParts.forEach(x => {
                  this.inputs.text += x;
                });

                console.error(e);
                if (e.response.status === 403) {
                  this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
                }
              });
          }

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    sendPhotos(photos, text) {
      return new Promise(async (resolve, reject) => {
        try {
          let tempPhotos = [...photos];
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          this.replyToMessage = {};

          for (let i = 0; i < tempPhotos.length; i++) {
            await messagesApi
              .sendPhoto(
                this.currentChatId,
                tempPhotos[i].file,
                text,
                tempReplyToMessage.messageId
              )
              .then(res => {
                //remove photo
                tempPhotos.splice(i, 1);
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', false);
                i--;
              })
              .catch(e => {
                this.replyToMessage = tempReplyToMessage || {};
                this.inputs.text = text;

                tempPhotos[i].status = 'error';
                console.error(e);
                if (e.response.status === 403) {
                  this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
                }
              });
          }

          this.tempFiles = [...this.tempFiles, ...tempPhotos];
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    sendDocuments(documents, text) {
      return new Promise(async (resolve, reject) => {
        try {
          let tempDocuments = [...documents];
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          this.replyToMessage = {};

          for (let i = 0; i < tempDocuments.length; i++) {
            await messagesApi
              .sendDocument(
                this.currentChatId,
                tempDocuments[i].file,
                text,
                tempReplyToMessage.messageId
              )
              .then(res => {
                //remove document
                tempDocuments.splice(i, 1);
                this.$store.commit('chat/SET_IS_BOT_BLOCKED', false);
                i--;
              })
              .catch(e => {
                this.replyToMessage = tempReplyToMessage || {};
                this.inputs.text = text;

                tempDocuments[i].status = 'error';
                console.error(e);
                if (e.response.status === 403) {
                  this.$store.commit('chat/SET_IS_BOT_BLOCKED', true);
                }
              });
          }

          this.tempFiles = [...this.tempFiles, ...tempDocuments];
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    changeFile(file, index) {
      const thumbnail =
        file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

      file = {
        status: '',
        file,
        ...(thumbnail ? { thumbnail } : {}),
        size: convertBytesToMegabytes(file.size),
      };

      this.files.splice(index, 1, file);
      this.inputs.files = [];
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length === 0) this.close();
    },

    addFiles(e) {
      let files = Array.from(e.currentTarget.files);
      if (files.length === 0) return;

      files = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

        return {
          status: '',
          file,
          ...(thumbnail ? { thumbnail } : {}),
          size: convertBytesToMegabytes(file.size),
        };
      });

      this.files = [...this.files, ...files];
      this.inputs.files = [];
      this.$refs['file-input'].value = '';
    },

    close() {
      this.show = false;
    },

    openImageEditor(index) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.editedImage = {
            index,
            URL: reader.result,
          };
          this.isImageEditorOpen = true;
        },
        { once: true }
      );
      reader.readAsDataURL(this.files[index].file);
    },

    closeImageEditor() {
      this.isImageEditorOpen = false;
    },
  },
};
</script>

<style lang="scss">
.upload-dialog {
  .textarea textarea {
    max-height: 84px !important;
  }

  .file-list {
    &__item {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .thumbnail {
    border-radius: 8px;
    overflow: hidden;

    &__container {
      cursor: pointer;
      background: #3d3d3d;
    }

    &__image {
      cursor: pointer;
      width: 100%;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3d3d3d;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
  }

  .reload-btn {
    .v-input__prepend-outer {
      margin: 0;
    }

    .v-icon {
      font-size: 1rem;
    }
  }
}
</style>
