<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <v-dialog :value="show" persistent max-width="600px">
    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Нова Розсилка</span>
      </v-card-title>

      <v-card-text>
        <bulk-messages-filters
          @change="handleChangeFilters"
          @resetFilters="handleResetFilters"
          :filters="filters"
          :userIdsCsvOriginalFileName="userIdsCsvOriginalFileName"
        />

        <v-row class="mb-1">
          <v-col cols="6">
            <v-menu
              v-model="calendarMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :nudge-bottom="8"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inputs.date"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar"
                  :error="dateError"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="inputs.date"
                @input="calendarMenu = false"
                color="blue"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :nudge-bottom="8"
              ref="timeMenu"
              :return-value.sync="inputs.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inputs.time"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  :error="dateError"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="inputs.time"
                @click:minute="$refs.timeMenu.save(inputs.time)"
                format="24hr"
                full-width
                color="blue"
              >
              </v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-text-field
          v-model="inputs.title"
          label="Назва"
          outlined
          dense
          hide-details
          class="mb-4"
        />

        <v-textarea
          v-model="inputs.message"
          label="Написати повідомлення..."
          auto-grow
          outlined
          dense
          hide-details
          rows="10"
          row-height="15"
        />

        <add-files-section :files="this.files" @change="handleFilesChange" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bulkMessagesApi from '@/api/admin/bulkMessages';
import BulkMessagesFilters from './BulkMessagesFilters.vue';
import AddFilesSection from '../../../common/add-files-section/AddFilesSection.vue';
import { BULK_MESSAGES_FILTER_TYPE } from './constants';
import { formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

const {
  LANGUAGE,
  PAY_FREQUENCY,
  PLATFORM,
  IS_CASH_PAY,
  APP,
  NATIONALITY,
  CITY,
  IS_CONTRACT,
  CONTRACT_TYPE,
  IS_STUDENT,
  IS_TAXI_DRIVER,
  IS_DOCUMENT_MISSING,
  USER_IDS_CSV,
  USER_IDS,
} = BULK_MESSAGES_FILTER_TYPE;

const defaultFilters = {
  [LANGUAGE]: '',
  [PAY_FREQUENCY]: '',
  [PLATFORM]: '',
  [IS_CASH_PAY]: '',
  [APP]: [],
  [NATIONALITY]: [],
  [CITY]: [],
  [IS_CONTRACT]: '',
  [CONTRACT_TYPE]: '',
  [IS_STUDENT]: '',
  [IS_TAXI_DRIVER]: '',
  [IS_DOCUMENT_MISSING]: '',
  [USER_IDS_CSV]: null,
  [USER_IDS]: [],
};

export default {
  name: 'BulkMessagesCreateDialog',
  components: {
    BulkMessagesFilters,
    AddFilesSection,
  },

  props: {
    show: Boolean,
    bulkMessageToEdit: Object,
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      loading: false,
      dateError: false,

      calendarMenu: false,
      timeMenu: false,

      inputs: {
        title: '',
        message: '',
        date: formatDate(new Date(), '-').split('-').reverse().join('-'),
        time: formatTime(new Date().getTime() + 3 * 60000),
      },

      filters: { ...defaultFilters },

      // this is used to show that we have a filter based on userIds (in edit mode)
      userIdsCsvOriginalFileName: '',
      files: [],
    };
  },

  watch: {
    'inputs.date'() {
      this.dateError = false;
    },

    'inputs.time'() {
      this.dateError = false;
    },

    show() {
      this.inputs.date = formatDate(new Date(), '-')
        .split('-')
        .reverse()
        .join('-');
      this.inputs.time = formatTime(new Date().getTime() + 3 * 60000);
    },

    bulkMessageToEdit() {
      if (this.bulkMessageToEdit) {
        const date = new Date(this.bulkMessageToEdit.scheduleTime);

        this.inputs.title = this.bulkMessageToEdit.title;
        this.inputs.message = this.bulkMessageToEdit.message;
        this.inputs.date = formatDate(date, '-').split('-').reverse().join('-');
        this.inputs.time = formatTime(date);
        this.filters = this.bulkMessageToEdit.filters || { ...defaultFilters };
        this.userIdsCsvOriginalFileName =
          this.bulkMessageToEdit?.userIdsCsvOriginalFileName;
        this.files = this.initializeFiles(this.bulkMessageToEdit.files);
      }
    },
  },

  methods: {
    handleChangeFilters(key, value) {
      if (key === LANGUAGE) {
        this.filters[NATIONALITY] = [];
      }

      if (key === NATIONALITY) {
        this.filters[LANGUAGE] = '';
      }

      if (key === USER_IDS_CSV) {
        // uploading USER_IDS_CSV resets all filters apart from language
        this.filters = {
          ...defaultFilters,
          [LANGUAGE]: this.filters[LANGUAGE],
        };
      }

      this.filters[key] = value;
    },
    handleResetFilters() {
      this.filters = { ...defaultFilters };
    },
    extractNonEmptyFilters() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([, value]) => value?.length !== 0)
      );
    },

    handleFilesChange(newFiles) {
      this.files = newFiles;
    },

    createBulkMessage(bulkMessage) {
      bulkMessagesApi
        .create(bulkMessage)
        .then(res => {
          this.close();
          this.$emit('create');
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editBulkMessage(bulkMessage) {
      bulkMessagesApi
        .edit({ id: this.bulkMessageToEdit._id, data: bulkMessage })
        .then(res => {
          this.close();
          this.$emit('edit');
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      if (this.loading) return;

      const bulkMessage = {
        title: this.inputs.title,
        message: this.inputs.message,
        scheduleTime: new Date(
          this.inputs.date + 'T' + this.inputs.time + ':00'
        ).getTime(),
        filters: this.extractNonEmptyFilters(),
        files: this.files.map(item => item.file),
      };

      if (new Date().getTime() >= bulkMessage.scheduleTime) {
        this.dateError = true;
        return;
      }

      this.loading = true;

      const saveMethod = this.bulkMessageToEdit
        ? this.editBulkMessage
        : this.createBulkMessage;

      saveMethod(bulkMessage);
    },

    /** @param files - Array<{ name: string;  mimetype: string; pathToFile: string }> */
    initializeFiles(files) {
      if (!files) return [];

      return files.map(file => {
        const thumbnail =
          file.mimetype.indexOf('image') === 0
            ? `${this.blobContainerUrl}${file.folder}/${file.id}`
            : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });
    },

    close() {
      this.inputs = {
        title: '',
        message: '',
        date: formatDate(new Date(), '-').split('-').reverse().join('-'),
        time: formatTime(new Date().getTime() + 3 * 60000),
      };
      this.handleResetFilters();
      this.files = [];
      this.$emit('close');
    },
  },
};
</script>
